import { createSlice } from "@reduxjs/toolkit";
import { getCurrentDateTime, parse, stringify } from "../utils/Common";

const initialState = {
  list: [],
  currentChat: {},
  chatMessages: [],
  hasMore: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    ClearChatMessage: (state, action) => {
      state.chatMessages = [];
      state.currentChat = {};      
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {
    LoadChats: (state, action) => {
      if (action.payload) {
        state.list = action.payload;
      }
    },
    UpdateCurrentChat: (state, action) => {
      if (action.payload) {
        state.currentChat = action.payload;
      }
    },
    AddMessage: (state, action) => {
      if (action.payload) {
        let obj = action.payload;
        let chatMessages = state.chatMessages;

        const filterMsg = state.chatMessages.filter(r=> r.hash == obj.hash)
        if (filterMsg.length>0) {
          return
          
        }
        chatMessages.push(obj);
        for (let iterator of chatMessages) {
          if (
            iterator.id === obj.relatedMessageId &&
            obj.chatType.name === "Processing Payment"
          ) {
            let message = parse(iterator.message);
            message.isPaid = true;
            iterator.message = stringify(message);
          }
        }
        state.chatMessages = chatMessages;
      }
    },
    LoadChatMessage: (state, action) => {
      if (action.payload) {
        if (action.payload.length === 20) {
          state.hasMore = true;
        } else {
          state.hasMore = false;
        }
        state.chatMessages = [...action.payload, ...state.chatMessages];
      }
    },

    UnReadAllChatMessage: (state, action) => {
      if (action.payload) {
        state.countNotification =action.payload.count;
      }
    },
    UpdateCurrentChatRead: (state, action) => {
      if (action.payload) {
        let list = state.list;
        for (let iterator of list) {
          if (state.currentChat.id === iterator.id) {
            iterator.isRead = true;
          }
        }
        state.list = list;
      }
    },
    UpdateMessage: (state, action) => {
      if (action.payload) {
        let list = state.list;
        let index = -1;
        for (let [key, iterator] of list.entries()) {
          if (iterator.id === action.payload.chatId) {
            index = key;
          }
        }

        if (index !== -1) {
          let selected = list[index];
          selected.lastMessage = action.payload.message;
          selected.sender = action.payload.userId;
          selected.isRead = (state.currentChat.id === selected.id)? true: false;
          selected.lastMessageAt = getCurrentDateTime();
          list.splice(index, 1);
          list.unshift(selected);
        }
        state.list = list;
      }
    },
    UpdateOnline: (state, action) => {
      if (action.payload) {
        let list = state.list;
        for (let iterator of list) {
          if (
            ((iterator.firstUser &&
              iterator.firstUser.id === action.payload.userId) ||
              (iterator.secondUser &&
                iterator.secondUser.id === action.payload.userId)) &&
            action.payload.currentUser !== action.payload.userId
          ) {
            iterator.online = true;
            iterator.current = action.payload.current
          }
        }
        state.list = list;
      }
    },
    UpdateOffline: (state, action) => {
      if (action.payload) {
        let list = state.list;
        for (let iterator of list) {
          if (
            (iterator.firstUser &&
              iterator.firstUser.id === action.payload.userId) ||
            (iterator.secondUser &&
              iterator.secondUser.id === action.payload.userId)
          ) {
            if (iterator.current < action.payload.current) {
              iterator.online = false;
              iterator.current = action.payload.current
            }
          }
        }
        state.list = list;
      }
    },
  },
});

export const { ClearChatMessage } = chatSlice.actions;
export const Chats = (state) => state.chat;
export default chatSlice.reducer;
