import { useSelector } from 'react-redux'

export function useUser() {
  const user = useSelector((state) => state.user)
  return user.userData;
}

export function useWallet() {
  const user = useSelector((state) => state.user)
  return user.wallet;
}

export function usePaymentHistory() {
  const user = useSelector((state) => state.user)
  return user.paymentHistory;
}

export function useWithDrawal() {
  const user = useSelector((state) => state.user)
  return user.withDrawal;
}

export function useSubscription() {
  const user = useSelector((state) => state.user)
  return user.subscription;
}

export function useCards() {
  const user = useSelector((state) => state.user)
  return user.cards;
}

export function useBanks() {
  const user = useSelector((state) => state.user)
  return user.banks;
}

export function useBankData() {
  const user = useSelector((state) => state.user)
  return user.bankData;
}

export function useAddressData() {
  const user = useSelector((state) => state.user)
  return user.addressData;
}

export function useAddress() {
  const user = useSelector((state) => state.user)
  return user.address;
}


export function useBillingAddress() {
  const user = useSelector((state) => state.user)
  return user.billingAddress;
}


export function useChatList() {
  const chat = useSelector((state) => state.chat)
  return chat.list;
}

export function useCountNotification() {
  const countNotification = useSelector((state) => state.chat.countNotification)
  return countNotification;
}

export function useChatMessages() {
  const chat = useSelector((state) => state.chat)
  return chat.chatMessages;
}

export function useFreeProfiles() {
  const user = useSelector((state) => state.user);
  return user.freeProfiles;
}

export function useCurrentWallet() {
  const user = useSelector((state) => state.user);
  return user.walletCurrent;
}

export function useHasMore() {
  const chat = useSelector((state) => state.chat)
  return chat.hasMore;
}

/**
 * Reports
 */

export function useReports() {
  const reports = useSelector((state) => state.reports)
  return reports;
}