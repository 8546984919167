import logo from "../assets/images/logo-1.svg";
import header_logo from "../assets/images/logo-icon.svg";
import coming_soon_logo from "../assets/images/coming_soon_logo.svg";
import splash from "../assets/images/logo.svg";
import backImg from "../assets/images/back-btn.png";
import playImg from "../assets/images/play-btn.png";
import tellMeMore from "../assets/images/tell-me-more.png";
import star from "../assets/images/star.png";
import dollarIcon from "../assets/images/dollar-icon.png";
import dollarIcon2 from "../assets/images/dollar-icon-2.svg";
import cameraIcon from "../assets/images/ic_camera_upload.png";
import cameraIcon2 from "../assets/images/ic_camera_upload1.png";
import upload1 from "../assets/images/upload-img1.png";
import upload2 from "../assets/images/upload-img2.png";
import upload3 from "../assets/images/upload-img3.png";
import default_profile from "../assets/images/default-profile.jpeg";
import uploadImgIcon from "../assets/images/browse-icon.png";
import profileMale from "../assets/images/default-profile-male.jpg";
import profilePic from "../assets/images/proifle-img.png";
import profile_Img from "../assets/images/profile-img.png";
import closeIcon from "../assets/images/close-icon.png";
import eyeclose from "../assets/images/ic_eye_close.png";
import content_library_1 from "../assets/images/content-library-1.png";
import content_library_2 from "../assets/images/content-library-2.png";
import uploadLink from "../assets/images/uploaded-img.png";
import idDefault from "../assets/images/id_default.jpg";

import chat_icon from "../assets/images/chat-icon.png";
import notification_icon from "../assets/images/notification-icon.png";
import notification_icon2 from "../assets/images/notification-icon-2.png";
import profile_icon from "../assets/images/profile-icon.png";
import messageIcon from "../assets/images/message-icon.png";
import browseImg from "../assets/images/browse-img.png";
import deleteIcon from "../assets/images/delete-icon.png";
import photo_video from "../assets/images/photo-video-1.png";
import transaction_successful from "../assets/images/transaction_successful.svg";

//chat
import sent_icon from "../assets/images/sent-icon.png";
import attach_icon from "../assets/images/attach-icon.png";
import add_icon from "../assets/images/add-icon.png";
import mediaIcon from "../assets/images/mediaIcon.png";

//consumer
import video_image_folder from "../assets/images/ic_video_img_folder.svg";
import tab_search_deactive from "../assets/images/tab_search_deactive.png";
import filter_icon from "../assets/images/filter.svg";

/////profile
import my_acount_img from "../assets/images/my_acount_img.jpeg";
import edit_icon from "../assets/images/edit-icon.svg";
import my_wallet from "../assets/images/my_wallet.svg";
import ic_manage_card from "../assets/images/ic_manage_card.svg";
import manage_subscription from "../assets/images/manage_subscription.svg";
import ic_image_content from "../assets/images/ic_image_content.svg";
import Setting from "../assets/images/Setting.svg";
import address_icon from "../assets/images/address_icon.svg";
import bank_icon from "../assets/images/bank_icon.svg";
import chevron_right from "../assets/images/chevron_right.svg";
import ic_add_card from "../assets/images/ic_add_card.svg";
import trash from "../assets/images/trash.svg";

import tranparent_left_arrow from "../assets/images/tranparent-left-arrow.svg";
import ic_message from "../assets/images/ic_message.svg";

import credit from "../assets/images/credit.svg";
import debit from "../assets/images/debit.svg";
import ic_wallet from "../assets/images/ic_wallet.png";
import ic_withdraw from "../assets/images/withdrawl.png";
import ic_add_money_wallet from "../assets/images/ic_add_money_wallet.svg";
import ic_add_money from "../assets/images/ic_add_money.svg";
import save_money from "../assets/images/save_money.svg";
import monthly_icon from "../assets/images/monthly_icon.svg";
import ic_delete_card from "../assets/images/ic_delete_card.svg";

import ic_delete_account from "../assets/images/ic_delete_account.svg";
import address_icon_delete from "../assets/images/address_icon_delete.svg";

import cancel_subscription from "../assets/images/cancel_subscription.svg";
import card_logos from "../assets/images/card_logos.jpeg";
// import save_money from "../assets/images/address_icon_delete.svg";
import list_icom from "../assets/images/list-icon.png";
import logo_login from "../assets/images/logo_login.svg";

import plus from "../assets/images/plus-large-svgrepo-com.svg";
import minus from "../assets/images/minus-svgrepo-com.svg";

const images = {
  splash,
  logo,
  coming_soon_logo,
  header_logo,
  backImg,
  tellMeMore,
  star,
  dollarIcon,
  dollarIcon2,
  cameraIcon,
  cameraIcon2,
  profilePic,
  closeIcon,
  upload1,
  upload2,
  upload3,
  uploadImgIcon,
  eyeclose,
  content_library_1,
  content_library_2,
  chat_icon,
  notification_icon,
  notification_icon2,
  profile_icon,
  playImg,
  messageIcon,
  browseImg,
  deleteIcon,
  video_image_folder,
  tab_search_deactive,
  photo_video,
  default_profile,
  filter_icon,
  tranparent_left_arrow,
  profile_Img,
  ic_message,
  credit,
  debit,
  ic_wallet,
  ic_withdraw,
  ic_add_money_wallet,
  ic_add_money,
  sent_icon,
  attach_icon,
  add_icon,
  mediaIcon,
  uploadLink,
  edit_icon,
  my_wallet,
  ic_manage_card,
  manage_subscription,
  ic_image_content,
  Setting,
  chevron_right,
  ic_add_card,
  trash,
  my_acount_img,
  address_icon,
  bank_icon,
  transaction_successful,
  save_money,
  monthly_icon,
  ic_delete_card,
  ic_delete_account,
  address_icon_delete,
  cancel_subscription,
  card_logos,
  list_icom,
  logo_login,
  idDefault,
  profileMale,
  plus,
  minus
};

export default images;
